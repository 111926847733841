<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar :curIndex="2"></topBar>
		<!-- 广告位 -->
		<div class="coupon_top_banner" v-if="bannerList.length>0">
			<el-carousel
			    :height="screenwidth"
				class="swiper"
				indicator-position="none"
				arrow="never">
				<el-carousel-item class="item" v-for="(item, index) in bannerList" :key="index" >
					<img 
						class="pic pointer"
						:src="item.imageUrl"
						@click="$util.windowOpen(item.redirectUrl)"
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 优惠券中心 -->
		<div class="centertitle">
			<div class="textname">
				<div class="text">领券中心</div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="coupon_empty_box" v-if="dataList.length<=0">
			<img class="img" src="../assets/static/Group2242.png"/>
			<div class="text">近期没有优惠券活动，去<span @click="$util.routerPath('/home')">首页</span>逛逛吧！</div>
		</div>
		<div class="block20"></div>
		<div class="couponcenter_list_box">
			<div class="cpitem" v-for="(item,index) in dataList" :key="index">
				<div class="top_box">
					<div class="amout">￥<span>{{ item.amount }}</span></div>
					<div class="conbox">
						<div class="text1">满{{ item.minAmount }}减{{ item.amount }}元代金券</div>
						<div class="text2">优惠类型：
						     <span v-if="item.couponType==1">限品类券，仅限指定商品可用</span>
							 <span v-if="item.couponType==0">全品类券，全部商品可用</span>
						</div>
						<div class="text3">{{ item.startTime }}至{{ item.endTime }}</div>
					</div>
					<div class="line"></div>
					<div class="btndiv">
						<div class="lingqu" v-if="item.canFlag==0" @click="gotoLingqu(index)">领取</div>
						<div class="default_text" v-if="item.canFlag==1">已领取</div>
					</div>
				</div>
				<div class="coupon_sm">
					<div class="shuoming" @click="openbody(index)">
						<div class="bsnname">优惠券使用规则</div>
						<img v-if="item.ifbody" class="jiantou" src="../assets/static/jiantou_up.png"/>
						<img v-if="!item.ifbody" class="jiantou" src="../assets/static/jiantou_down.png"/>
					</div>
					<!-- <div class="sm_con" v-if="item.ifbody">
						<div>{{item.body}}</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<div class="pagination_box_white w1240" v-if="dataList.length>0">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<div class="block20"></div>
		<!-- 登录页面 -->
		<loginForm :isShow="isShowLoginForm" @loginFormSuccess="loginFormSuccess" @openzhuce="openzhuce" @handleLoginForm="handleLoginForm"></loginForm>
		<!-- 注册采购商弹窗 -->
		<registerForm :isShow="dialogFormRegister" @handlezhuceiForm="handlezhuceiForm"></registerForm>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
import  loginForm  from "../components/loginForm/loginForm.vue";
import  registerForm  from "../components/registerForm/registerForm.vue";
export default {
	components: {
		topBar,
		loginForm,
		registerForm,
	},
	data() {
		return {
			dialogFormRegister:false,//采购认证
			isShowLoginForm:false,//登录操作
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			bannerList:[],
			screenwidth:"500px",
		};
	},
	mounted: function() {
		this.$store.commit('SET_CURPAGE', 'couponCenter')
		window.onresize = () => {
		  return (() => {
		    this.screenwidth = (document.documentElement.clientWidth * 500) / 1920 + "px";
		  })();
		};
		var _this = this
		//轮播图
		this.getwheelPage(21)
		if(!this.isLogin){
			this.$alert('登录时限失效，请重新登录~', '温馨提示', {
			    confirmButtonText: '确定',
			    callback: action => {
			        // _this.$router.push({
			        // 	name: "home"
			        // });
					_this.handleLoginForm("open")
			    }
			});
		}else{
			this.getdataPage()
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName',"uuid"]),
	},
	methods: {
		...mapMutations(['SET_Data','SET_ISLOGIN']),
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 8
				params["type"] = type
				this.$http.post('AdvertModule/wheel-advert/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							_this.bannerList = records
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//列表
		getdataPage() {
			var _this = this
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["userUuid"] = this.uuid
			this.$http.post('coupon/coupon/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records || res.data;
					_this.total =  res.data.total || 1;
					records.forEach((item,index)=>{
						item["ifbody"] = false //展开详细信息
						var date = item.startDate.split("T")
						if (date.length > 0) {
						  item["startTime"] = date[0].replace(/-/g, '.')
						}
						var date1 = item.endDate.split("T")
						if (date1.length > 0) {
						  item["endTime"] = date1[0].replace(/-/g, '.')
						}
					})
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//打开
		//展开信息
		openbody(index){
		    var body = this.dataList[index].body
		    this.$alert(body, '优惠券使用规则', {
		        confirmButtonText: '确定',
		    });
		},
		//立即领取优惠券
		gotoLingqu(index){
		    var _this = this
		    var params = {}
		    params["couponUuid"] = this.dataList[index].couponUuid
		    params["userUuid"] = this.uuid
		    // 发动请求
			this.$http.post('coupon/couponUser/receiveCoupons', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.$message.success('领取成功~');
					_this.$message.success('领取成功~');
					_this.dataList[index].canFlag = 1
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			//请求
		},
		//登录操作
		handleLoginForm(type){
			if(type=="open"){
				this.isShowLoginForm = true
			}else{
				this.isShowLoginForm = false
			}
		},
		openzhuce(){
			this.isShowLoginForm = false
			this.dialogFormRegister = true
		},
		//打开注册认证
		handlezhuceiForm(type){
			if(type=="open"){
				this.dialogFormRegister = true
			}else{
				this.dialogFormRegister = false
			}
		},
		//登录成功回调
		loginFormSuccess(){
			this.handleLoginForm("close")
		},
		
	}
};
</script>
<style lang="scss" scoped>
.coupon_top_banner{
	width: 100%;
}
.centertitle{
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	box-shadow: inset 0px 1px 0px 1px #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	.textname{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.text{
			height: 58px;
			line-height: 58px;
			font-size: 18px;
			font-weight: bold;
			color: #000000;
		}
		.line{
			width: 24px;
			height: 2px;
			background: #1370FB;
		}
	}
}
.couponcenter_list_box{
	width: 1240px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	.cpitem{
		width: 393.3px;
		margin: 20px 10px 0;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		position: relative;
		.top_box{
			width: 100%;
			height: 120px;
			display: flex;
			align-items: center;
			.amout{
				width: 98px;
				text-align: center;
				font-size: 13px;
				font-weight: 600;
				color: #FA6163;
				span{
					font-size: 25px;
				}
			}
			.conbox{
				width: 198px;
				min-height: 88px;
				display: flex;
				flex-direction: column;
				.text1{
					font-size: 13px;
					line-height: 16px;
					font-weight: 600;
					color: #333333;
				}
				.text2{
					margin-top: 10px;
					font-size: 13px;
					line-height: 18px;
					font-weight: 400;
					color: #666666;
				}
				.text3{
					margin-top: auto;
					font-size: 13px;
					font-weight: 400;
					color: #999999;
				}
			}
			.line{
				width: 0px;
				margin-left: 8px;
				height: 88px;
				border: 1px dashed #E0E0E0;
			}
			.btndiv{
				flex: 1;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				.lingqu{
					color: #1370FB;
					cursor: pointer;
				}
				.default_text{
					color: #999999;
				}
			}
		}
		.coupon_sm{
			width: 100%;
			position: relative;
			z-index: 8;
			border-radius: 4px 4px 4px 4px;
			border-top: 1px solid #f2f2f2;
			.shuoming {
				padding: 0 16px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				.bsnname {
					font-size: 12px;
					font-weight: 400;
					color: #999999;
				}
				.jiantou {
					width: 10px;
					height: 6px;
				}
			}
		}
	}
}
</style>
